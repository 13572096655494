/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.top-image {
    background-image: url('./assets/images/top.png');
    background-size: cover;
}

.small-logo {
    width: 150px;
    /* Adjust as needed */
}

.responsive-height {
    min-height: auto;
    /* Full screen height by default */
}

@media (min-width: 1024px) {

    /* Adjust this breakpoint as needed */
    .responsive-height {
        min-height: 100vh;

        /* Remove the min-height on larger screens */
    }
}

.submit-btn {
    background-color: #027BC2;
}

.submit-btn:hover {
    background-color: hsl(202, 97.9%, 34.4%);
}

.terms-policy-btn-color {
    color: #00000080;
}

/* Add this to your custom.css or index.css */